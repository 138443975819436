
import { throttle } from '~/utils';

export default {
  name: 'base-up-button',
  props: {
    position: {
      type: Object,
      default: () => ({ right: '20px', bottom: '20px' }),
    },
    breakPoint: {
      type: Number,
      default: 500,
    },
  },
  data () {
    return {
      checkScrollThrottle: throttle(this.checkScrollPosition, 500),
      disabled: true,
    };
  },
  computed: {
    classes () {
      return {
        'base-up-button--disabled': this.disabled,
      };
    },
  },
  mounted () {
    this.createScrollListener();
    this.checkScrollPosition();
  },
  beforeDestroy () {
    this.destroyScrollListener();
  },
  methods: {
    scrollToTop () {
      this.$forceNextTick(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },
    checkScrollPosition () {
      const scrollTop = window.scrollY;
      if (scrollTop > this.breakPoint) this.disabled = false;
      else this.disabled = true;
    },
    createScrollListener () {
      document.addEventListener('scroll', this.checkScrollThrottle);
    },
    destroyScrollListener () {
      document.removeEventListener('scroll', this.checkScrollThrottle);
    },
  },
};
