export function throttle (func, waitTime) {
  let isThrottling = false;
  let hasTrailingCall = false;
  let lastContext;

  const invokeFunction = (context, ...args) => {
    func.apply(context, args);
    isThrottling = true;

    setTimeout(() => {
      isThrottling = false;
      if (hasTrailingCall) {
        invokeFunction(lastContext, ...args);
        lastContext = undefined;
        hasTrailingCall = false;
      }
    }, waitTime);
  };

  function wrapper (...args) {
    if (!isThrottling) {
      invokeFunction(this, ...args);
    } else {
      hasTrailingCall = true;
      lastContext = this;
    }
  }

  return wrapper;
}
