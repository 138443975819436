
import { mapGetters } from 'vuex';

export default {
  name: 'base-footer',
  computed: {
    ...mapGetters('sulu', ['menu']),
    navigation () {
      return this.menu.slice(0, 5);
    },
    year () {
      const currentYear = new Date().getFullYear();
      return `${currentYear - 1}-${currentYear}`;
    },
  },
};
