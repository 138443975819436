import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0b4e6fb4"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=0b4e6fb4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzOffline: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/offline/index.vue').default,RzNotificationCenter: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/notification-center/index.vue').default,BaseHeader: require('/opt/hkok.ru/src/components/base/header/index.vue').default,RzContainer: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/container/index.vue').default,BaseFooter: require('/opt/hkok.ru/src/components/base/footer.vue').default,BaseUpButton: require('/opt/hkok.ru/src/components/base/up-button.vue').default})
