
export default {
  name: 'default',
  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  watch: {
    $locale: {
      handler (value) {
        this.$cookiz.set('defaultLanguage', value, { path: '/', maxAge: 60 * 60 * 24 * 365 });
        this.$bus.$emit('locale-changed');
      },
    },
  },
};
